#app {
  height: 100%;

  /* 
  create a pancake stack layout 
  https://web.dev/one-line-layouts/#04.-pancake-stack:-grid-template-rows:-auto-1fr-auto
  */
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: auto;
}
